import { observer } from 'mobx-react-lite';
import React, { useState } from 'react';
import { Box, VStack, HStack, Input, Button, Text } from '@chakra-ui/react';

import { useStore } from '../../hooks/useStore';

import InfoDisplay from './InfoDisplay';

const SubcategoryDetails = () => {
  const {
    subcategoryStore: { displayedSubcategory, editSubcategory },
  } = useStore();

  const [isEditingWeight, setIsEditingWeight] = useState(false);
  const [minPackageWeight, setMinPackageWeight] = useState(
    displayedSubcategory?.minPackageWeightInOz || ''
  );
  const [loading, setLoading] = useState(false);

  const handleEditWeight = () => {
    setIsEditingWeight(true);
  };

  const handleSaveWeight = async () => {
    try {
      setLoading(true);
      await editSubcategory(displayedSubcategory.id, { minPackageWeightInOz: parseFloat(minPackageWeight) });
      setIsEditingWeight(false);
    } catch (error) {
      console.error('Failed to update min package weight:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleWeightChange = (event: any) => {
    setMinPackageWeight(event.target.value);
  };

  return (
    <Box p={4}>
      <VStack spacing={4} align="start">
        <InfoDisplay title={'Subcategory title:'} value={displayedSubcategory?.title}/>
        <InfoDisplay title={'Category Title:'} value={displayedSubcategory?.category.title}/>

        <HStack>
          <Text fontWeight="bold">Min Package Weight (in oz):</Text>
          {isEditingWeight ? (
            <>
              <Input
                value={minPackageWeight}
                onChange={handleWeightChange}
                placeholder="Enter weight in oz"
                type="number"
              />
              <Button onClick={handleSaveWeight} isLoading={loading}>
                Save
              </Button>
            </>
          ) : (
            <HStack>
              <Text>{displayedSubcategory?.minPackageWeightInOz || 'No data'}</Text>
              <Button onClick={handleEditWeight}>Edit</Button>
            </HStack>
          )}
        </HStack>
      </VStack>
    </Box>
  );
};

export default observer(SubcategoryDetails);
