import { endpoints } from '../constants/endpoints';
import { ListingQueryDto } from '../constants/types';

import { BaseApi } from './index';

export class ListingApi extends BaseApi<any> {
  constructor() {
    super(endpoints.listings.base);
  }

  async getActiveListings(query: Partial<ListingQueryDto>) {
    return this.fetch(endpoints.listings.active, { params: query });
  }

  async getSoldListings(query: Partial<ListingQueryDto>) {
    return this.fetch(endpoints.listings.sold, { params: query });
  }

  async getSoldListingById(id: string) {
    return this.get(endpoints.listings.soldById(id));
  }

  async getActiveListingById(id: string) {
    return this.get(endpoints.listings.activeById(id));
  }

  async deleteOrder(id: string) {
    return this.delete(endpoints.listings.orderById(id));
  }

  async deleteListing(id: string) {
    return this.delete(endpoints.listings.activeById(id));
  }

  async updateActiveListing(id: string, data: Partial<any>) {
    return this.patch(endpoints.listings.activeById(id), data);
  }

  async cancelOrder(id: string) {
    return this.patch(endpoints.listings.cancelById(id), {});
  }

  async featureListing(id: string, featured: boolean) {
    return (await this.patch(endpoints.listings.featureById(id), {
      featured,
    })) as unknown as { featured: boolean };
  }
}
