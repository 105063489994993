import React from 'react';
import { Box, Text, useColorModeValue } from '@chakra-ui/react';

import { Colors } from '../../theme/colors';

type PillProps = {
  isSeen: boolean;
};

const MessageTypePill = ({ isSeen }: PillProps) => {
  const textColorIsSeen = useColorModeValue(
    Colors.light.primary,
    Colors.dark.primary,
  );
  const textColorIsNotSeen = useColorModeValue(
    Colors.light.secondary,
    Colors.dark.secondary,
  );

  return (
    <Box
      display="inline-block"
      px={'20px'}
      py={'4px'}
      bg={'transparent'}
      color="white"
      borderRadius="full"
      border={`1px solid ${isSeen ? textColorIsSeen : textColorIsNotSeen}`}
    >
      <Text color={isSeen ? textColorIsSeen : textColorIsNotSeen}>
        {isSeen ? 'Seen' : 'Not seen'}
      </Text>
    </Box>
  );
};

export default MessageTypePill;
