import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Avatar, Box, HStack, Td, Text, Tr, useToast } from '@chakra-ui/react';

import { useStore } from '../../../hooks/useStore';
import useDebounce from '../../../hooks/useDebounce';
import SearchBar from '../../../components/common/SearchBar';
import CustomTable from '../../../components/table/CustomTable';
import { messageHeaders } from '../../../constants/tableHeaders';
import { parseDate } from '../../../utils/date';
import DeleteMenu from '../../../components/common/DeleteMenu';
import MessageTypePill from '../../../components/common/MessageTypePill';

const MessageScreen = () => {
  const [currentPage, setCurrentPage] = useState(1);

  const {
    chatStore: {
      messages,
      fetchMessages,
      totalMessages,
      messageSearch,
      setMessageSearch,
      deleteMessage,
      loading,
    },
  } = useStore();

  const toast = useToast();

  const debouncedSearch = useDebounce(messageSearch, 500);

  useEffect(() => {
    setCurrentPage(1);
    (async () => await fetchMessages({ page: 1, limit: 10 }))();
  }, [debouncedSearch]);

  const handleDelete = async (id: string) => {
    try {
      await deleteMessage(id);
      toast({
        title: 'Message deleted',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    } catch (e) {
      toast({
        title: 'Failed to delete message',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  return (
    <Box>
      <SearchBar
        search={messageSearch}
        setSearch={setMessageSearch}
        placeholder={'Search for chat users'}
      />
      <CustomTable
        headers={messageHeaders}
        rowsPerPage={10}
        totalRows={totalMessages}
        fetchDataTable={fetchMessages}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        loading={loading}
      >
        {(messages || []).map((message) => {
          const parsedDate = parseDate(message.createdAt.toString());

          return (
            <Tr key={message.id}>
              <Td>
                <HStack>
                  <Avatar
                    size="sm"
                    src={message.user.avatar}
                    name={message.user.userName || ''}
                  />
                  <Text>{message.user.userName || '-'}</Text>
                </HStack>
              </Td>
              <Td>
                <HStack>
                  <Avatar
                    size="sm"
                    src={message.otherUser.avatar}
                    name={message.otherUser.userName || ''}
                  />
                  <Text>{message.otherUser.userName || '-'}</Text>
                </HStack>
              </Td>
              <Td>
                {parsedDate.date}
                <br />
                {parsedDate.time}
              </Td>
              <Td>{message.message}</Td>
              <Td>
                <MessageTypePill isSeen={message.isSeen} />
              </Td>
              <Td>
                <DeleteMenu
                  title={'Delete message?'}
                  subtitle={'Are you sure you want to delete this message?'}
                  cancelActionLabel={'No, keep it'}
                  confirmActionLabel={'Delete message'}
                  onDelete={() => handleDelete(message.id)}
                  deleteActionLabel={'Delete message'}
                />
              </Td>
            </Tr>
          );
        })}
      </CustomTable>
    </Box>
  );
};

export default observer(MessageScreen);
