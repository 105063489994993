import { endpoints } from '../constants/endpoints';
import { RatingQueryDto } from '../constants/types';

import { BaseApi } from './index';

export class RatingApi extends BaseApi<any> {
  constructor() {
    super(endpoints.ratings.base);
  }

  async getAll(query: Partial<RatingQueryDto>) {
    return this.fetch(endpoints.ratings.all, { params: query });
  }

  async deleteRating(ratingId: string) {
    return this.delete(endpoints.ratings.delete(ratingId));
  }
}
