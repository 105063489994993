import {
  FaBoxOpen,
  FaCog,
  FaHome,
  FaList,
  FaQuestionCircle,
  FaShoppingCart,
  FaUsers,
  FaStar,
  FaInbox,
  FaSitemap
} from 'react-icons/fa';
import { RiAuctionFill, RiMessage2Fill } from "react-icons/ri";
import { IoMdChatboxes, } from "react-icons/io";


import { Routes } from '../router/routes';

import { SidebarItem } from './types';

export const sidebarRoutes: { [key: string]: SidebarItem } = {
  dashboard: {
    path: Routes.DashboardHome,
    label: 'Dashboard',
    icon: FaHome,
  },
  users: {
    path: Routes.DashboardUsers,
    label: 'Users',
    icon: FaUsers,
  },
  listings: {
    path: Routes.DashboardListings,
    label: 'Listings',
    icon: FaList,
    subRoutes: {
      active: {
        path: Routes.DashboardListingsActive,
        label: 'Active listings',
        icon: FaBoxOpen,
      },
      sold: {
        path: Routes.DashboardListingsSold,
        label: 'Sold listings',
        icon: FaShoppingCart,
      },
    },
  },
  subcategories: {
    path: Routes.DashboardSubcategory,
    label: 'Subcategories',
    icon: FaSitemap,
  },
  auctionMessages: {
    path: Routes.AuctionMessages,
    label: 'Auction messages',
    icon: RiAuctionFill,
  },
  chats: {
    path: Routes.Inbox,
    label: 'Inbox',
    icon: FaInbox,
    subRoutes: {
      chats: {
        path: Routes.Chats,
        label: 'Chats',
        icon: IoMdChatboxes
      },
      messages: {
        path: Routes.Messages,
        label: 'Messages',
        icon: RiMessage2Fill
      }
    }
  },
  ratings: {
    path: Routes.DashboardRatings,
    label: 'Ratings',
    icon: FaStar,
  },
  customerSupport: {
    path: Routes.DashboardCustomerSupport,
    label: 'Customer support',
    icon: FaQuestionCircle,
  },
  settings: {
    path: Routes.DashboardSettings,
    label: 'Settings',
    icon: FaCog,
  },
};
