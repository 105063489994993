import { ChatQueryDto } from '../constants/types';
import { endpoints } from '../constants/endpoints';

import { BaseApi } from './index';

export class ChatApi extends BaseApi<any> {
  constructor() {
    super(endpoints.chat.base);
  }

  async getChats(query: Partial<ChatQueryDto>) {
    return this.fetch(endpoints.chat.allChats, { params: query });
  }

  async deleteChat(chatId: string) {
    return this.delete(endpoints.chat.deleteChat(chatId));
  }

  async getMessages(query: Partial<ChatQueryDto>) {
    return this.fetch(endpoints.chat.allMessages, { params: query });
  }

  async deleteMessage(messageId: string) {
    return this.delete(endpoints.chat.deleteMessage(messageId));
  }
}
