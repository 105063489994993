import { IRating } from '@Encore-FastStartup-Phase-Out/encore-shared';
import { makeAutoObservable } from 'mobx';

import { RatingApi } from '../api/RatingApi';
import { RatingQueryDto } from '../constants/types';

export class RatingStore {
  api: RatingApi;
  ratings: IRating[] = [];
  totalRatings: number = 0;
  loading: boolean = false;
  ratingsSearch: string = '';

  constructor() {
    this.api = new RatingApi();
    makeAutoObservable(this, undefined, { autoBind: true });
  }

  setRatingsSearch(search: string) {
    this.ratingsSearch = search;
  }

  setRatings(ratings: IRating[]) {
    this.ratings = ratings;
  }

  setTotalRatings(totalRatings: number) {
    this.totalRatings = totalRatings;
  }

  setLoading(loading: boolean) {
    this.loading = loading;
  }

  async fetchRatings(query?: Partial<RatingQueryDto>) {
    query = {
      ...(this.ratingsSearch && { search: this.ratingsSearch }),
      ...(query || {}),
    };
    this.setLoading(true);
    const res = await this.api.getAll(query);
    this.setTotalRatings(res.count);

    if (query?.page === 1) {
      this.setRatings(res.items);
    } else {
      this.setRatings([...this.ratings, ...res.items]);
    }
    this.setLoading(false);
  }

  async deleteRating(id: string) {
    await this.api.deleteRating(id);
  }
}
