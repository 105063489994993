import {
  Avatar,
  Box, HStack,
  Td, Text,
  Tr,
  useToast
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { AuctionMessageType } from '@Encore-FastStartup-Phase-Out/encore-shared';

import { useStore } from '../../hooks/useStore';
import useDebounce from '../../hooks/useDebounce';
import SearchBar from '../../components/common/SearchBar';
import { activeAuctionMessageHeaders } from '../../constants/tableHeaders';
import { parseDate } from '../../utils/date';
import DeleteMenu from '../../components/common/DeleteMenu';
import CustomTable from '../../components/table/CustomTable';
import AuctionMessageTypePill from '../../components/common/AuctionMessageTypePill';

const ActiveAuctionMessagesScreen = () => {
  const [currentPage, setCurrentPage] = useState(1);

  const {
    auctionMessageStore: {
      fetchActiveAuctionMessages,
      activeAuctionMessages,
      activeAuctionMessagesSearch,
      setActiveAuctionMessagesSearch,
      totalActiveAuctionMessages,
      loading,
      deleteAuctionMessage,
    },
  } = useStore();

  const toast = useToast();

  const debouncedSearch = useDebounce(activeAuctionMessagesSearch, 500);

  useEffect(() => {
    setCurrentPage(1);
    (async () => await fetchActiveAuctionMessages({ page: 1, limit: 10 }))();
  }, [debouncedSearch]);

  const handleDelete = async (id: string) => {
    try {
      await deleteAuctionMessage(id);
      toast({
        title: 'Auction message deleted',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    } catch (e) {
      toast({
        title: 'Failed to delete auction message',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  return (
    <Box>
      <SearchBar
        search={activeAuctionMessagesSearch}
        setSearch={setActiveAuctionMessagesSearch}
        placeholder={'Search for auction message'}
      />
      <CustomTable
        headers={activeAuctionMessageHeaders}
        rowsPerPage={10}
        totalRows={totalActiveAuctionMessages}
        fetchDataTable={fetchActiveAuctionMessages}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        loading={loading}
      >
        {(activeAuctionMessages || []).map((auctionMessage) => {
          const parsedDate = parseDate(auctionMessage.createdAt);

          return (
            <Tr key={auctionMessage.id}>
              <Td>
                <AuctionMessageTypePill type={auctionMessage.type} />
              </Td>
              <Td>
                <HStack>
                  <Avatar
                    size="sm"
                    src={auctionMessage.user.avatar}
                    name={auctionMessage.user.userName || ''}
                  />
                  <Text>{auctionMessage.user.userName || '-'}</Text>
                </HStack>
              </Td>
              <Td>
                {parsedDate.date}
                <br />
                {parsedDate.time}
              </Td>
              <Td>{auctionMessage.listing.title}</Td>
              <Td>{auctionMessage.listing?.user?.userName}</Td>
              <Td>
                {auctionMessage.type === AuctionMessageType.Bid
                  ? `$${auctionMessage.amount}`
                  : 'N/A'}
              </Td>
              <Td>
                {auctionMessage.type === AuctionMessageType.Text
                  ? `$${auctionMessage.text}`
                  : 'N/A'}
              </Td>
              <Td>
                <DeleteMenu
                  title={'Delete auction message?'}
                  subtitle={
                    'Are you sure you want to delete this auction message?'
                  }
                  cancelActionLabel={'No, keep it'}
                  confirmActionLabel={'Delete auction message'}
                  onDelete={() => handleDelete(auctionMessage.id)}
                  deleteActionLabel={'Delete auction message'}
                />
              </Td>
            </Tr>
          );
        })}
      </CustomTable>
    </Box>
  );
};

export default observer(ActiveAuctionMessagesScreen);
