import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import {
  Avatar,
  Box,
  HStack,
  Td,
  Text,
  Tr,
  useToast,
} from '@chakra-ui/react';

import { useStore } from '../../hooks/useStore';
import useDebounce from '../../hooks/useDebounce';
import SearchBar from '../../components/common/SearchBar';
import CustomTable from '../../components/table/CustomTable';
import { ratingHeaders } from '../../constants/tableHeaders';
import { parseDate } from '../../utils/date';
import DeleteMenu from '../../components/common/DeleteMenu';

const RatingsScreen = () => {
  const [currentPage, setCurrentPage] = useState(1);

  const {
    ratingStore: {
      ratingsSearch,
      setRatingsSearch,
      ratings,
      loading,
      totalRatings,
      deleteRating,
      fetchRatings,
    },
  } = useStore();

  const toast = useToast();

  const debouncedSearch = useDebounce(ratingsSearch, 500);

  useEffect(() => {
    setCurrentPage(1);
    (async () => await fetchRatings({ page: 1, limit: 10 }))();
  }, [debouncedSearch]);

  const handleDelete = async (id: string) => {
    try {
      await deleteRating(id);
      toast({
        title: 'Success',
        description: 'Rating deleted successfully',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
      await fetchRatings({ page: 1, limit: 10 });
      setCurrentPage(1);
    } catch (e) {
      toast({
        title: 'Error',
        description: 'Failed to delete listing',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  return (
    <Box>
      <SearchBar
        search={ratingsSearch}
        setSearch={setRatingsSearch}
        placeholder={'Search for ratings'}
      />
      <CustomTable
        headers={ratingHeaders}
        rowsPerPage={10}
        totalRows={totalRatings}
        fetchDataTable={fetchRatings}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        loading={loading}
      >
        {(ratings || []).map((rating) => {
          const parsedDate = parseDate(rating.createdAt.toString());

          return (
            <Tr key={rating.id}>
              <Td>
                {rating.buyer ? (
                  <HStack>
                    <Avatar
                      size="sm"
                      src={rating.buyer.avatar}
                      name={rating.buyer.userName || ''}
                    />
                    <Text>{rating.buyer.userName || '-'}</Text>
                  </HStack>
                ) : (
                  <Text>Deleted user</Text>
                )}
              </Td>
              <Td>
                <HStack>
                  <Avatar
                    size="sm"
                    src={rating.seller.avatar}
                    name={rating.seller.userName || ''}
                  />
                  <Text>{rating.seller.userName || '-'}</Text>
                </HStack>
              </Td>
              <Td>
                {parsedDate.date}
                <br />
                {parsedDate.time}
              </Td>
              <Td>{rating.rate ? rating.rate : 'No rate'}</Td>
              <Td>{rating.experience ? rating.experience : 'No comment'}</Td>
              <Td>{rating.orderId}</Td>
              <Td>
                <DeleteMenu
                  title={'Delete rating?'}
                  subtitle={'Are you sure you want to delete this rating?'}
                  cancelActionLabel={'No, keep it'}
                  confirmActionLabel={'Delete rating'}
                  onDelete={() => handleDelete(rating.id)}
                  deleteActionLabel={'Delete rating'}
                />
              </Td>
            </Tr>
          );
        })}
      </CustomTable>
    </Box>
  );
};

export default observer(RatingsScreen);
