import { endpoints } from '../constants/endpoints';
import { PaginatedQueryDto } from '../constants/types';

import { BaseApi } from './index';

export class SubcategoryApi extends BaseApi<any> {
  constructor() {
    super(endpoints.subcategory.base);
  }

  async getSubcategories(query: Partial<PaginatedQueryDto>) {
    return this.fetch(endpoints.subcategory.base, { params: query });
  }

  async editSubcategory(id: string, data: any) {
    return this.patch(`${endpoints.subcategory.base}/${id}`, data);
  }

}
