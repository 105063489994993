import { AuthStore } from './AuthStore';
import { ListingStore } from './ListingStore';
import { UserStore } from './UserStore';
import { StatisticsStore } from './StatisticsStore';
import { SubcategoryStore } from './SubcategoryStore';
import { RatingStore } from './RatingStore';
import { AuctionMessageStore } from './AuctionMessageStore';
import { ChatStore } from './ChatStore';

export default class RootStore {
  authStore: AuthStore;
  listingStore: ListingStore;
  auctionMessageStore: AuctionMessageStore;
  chatStore: ChatStore;
  userStore: UserStore;
  statisticsStore: StatisticsStore;
  subcategoryStore: SubcategoryStore;
  ratingStore: RatingStore;

  constructor() {
    this.authStore = new AuthStore();
    this.listingStore = new ListingStore();
    this.userStore = new UserStore();
    this.statisticsStore = new StatisticsStore();
    this.ratingStore = new RatingStore();
    this.auctionMessageStore = new AuctionMessageStore();
    this.chatStore = new ChatStore();
    this.subcategoryStore = new SubcategoryStore();
  }
}
