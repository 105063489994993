export const Routes = {
  Home: '/',
  Login: '/auth/login',
  Dashboard: '/dashboard',
  DashboardHome: '/dashboard/home',
  DashboardUsers: '/dashboard/users',
  DashboardListings: '/dashboard/listings',
  DashboardListingsActive: '/dashboard/listings/active',
  DashboardListingsSold: '/dashboard/listings/sold',
  DashboardCustomerSupport: '/dashboard/customer-support',
  DashboardSettings: '/dashboard/settings',
  DashboardSubcategory: '/dashboard/subcategory',
  DashboardRatings: '/dashboard/ratings',
  AuctionMessages: '/dashboard/auctionMessages',
  Chats: '/dashboard/inbox/chats',
  Inbox: '/dashboard/inbox',
  Messages: '/dashboard/inbox/messages',
};
