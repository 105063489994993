import { makeAutoObservable } from 'mobx';
import { IAuctionMessage } from '@Encore-FastStartup-Phase-Out/encore-shared';

import { AuctionMessagesApi } from '../api/AuctionMessagesApi';
import { AuctionMessageQueryDto } from '../constants/types';

interface ExtendedIAuctionMessage extends IAuctionMessage {
  id: string;
  createdAt: string;
  updatedAt: string;
}

export class AuctionMessageStore {
  api: AuctionMessagesApi;
  activeAuctionMessages: ExtendedIAuctionMessage[] = [];
  totalActiveAuctionMessages: number = 0;
  loading: boolean = false;
  activeAuctionMessagesSearch: string = '';

  constructor() {
    this.api = new AuctionMessagesApi();
    makeAutoObservable(this, undefined, { autoBind: true });
  }

  setActiveAuctionMessages(auctionMessages: any[]) {
    this.activeAuctionMessages = auctionMessages;
  }

  setLoading(loading: boolean) {
    this.loading = loading;
  }

  setActiveAuctionMessagesSearch(value: string) {
    this.activeAuctionMessagesSearch = value;
  }

  setTotalActiveAuctionMessages(value: number) {
    this.totalActiveAuctionMessages = value;
  }

  async fetchActiveAuctionMessages(query?: Partial<AuctionMessageQueryDto>) {
    query = {
      ...(this.activeAuctionMessagesSearch && {
        search: this.activeAuctionMessagesSearch,
      }),
      ...(query || {}),
    };

    this.setLoading(true);
    const res = await this.api.getActiveAuctionMessages(query);
    this.setTotalActiveAuctionMessages(res.count);
    if (query?.page === 1) {
      this.setActiveAuctionMessages(res.items);
    } else {
      this.setActiveAuctionMessages([
        ...this.activeAuctionMessages,
        ...res.items,
      ]);
    }
    this.setLoading(false);
  }

  async deleteAuctionMessage(auctionMessageId: string) {
    await this.api.deleteAuctionMessage(auctionMessageId);
    const index = this.activeAuctionMessages.findIndex(
      (auctionMessage) => auctionMessage.id === auctionMessageId,
    );
    const copyActiveMessages = [...this.activeAuctionMessages];
    copyActiveMessages.splice(index, 1);
    this.setTotalActiveAuctionMessages(this.totalActiveAuctionMessages - 1);

    this.setActiveAuctionMessages(copyActiveMessages);
  }
}
