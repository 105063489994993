"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.states = exports.countries = void 0;
exports.countries = [{ title: 'United States' }];
exports.states = [
    { title: 'Alabama', shortcode: 'AL' },
    { title: 'Alaska', shortcode: 'AK' },
    { title: 'Arizona', shortcode: 'AZ' },
    { title: 'Arkansas', shortcode: 'AR' },
    { title: 'California', shortcode: 'CA' },
    { title: 'Colorado', shortcode: 'CO' },
    { title: 'Connecticut', shortcode: 'CT' },
    { title: 'Delaware', shortcode: 'DE' },
    { title: 'Florida', shortcode: 'FL' },
    { title: 'Georgia', shortcode: 'GA' },
    { title: 'Hawaii', shortcode: 'HI' },
    { title: 'Idaho', shortcode: 'ID' },
    { title: 'Illinois', shortcode: 'IL' },
    { title: 'Indiana', shortcode: 'IN' },
    { title: 'Iowa', shortcode: 'IA' },
    { title: 'Kansas', shortcode: 'KS' },
    { title: 'Kentucky', shortcode: 'KY' },
    { title: 'Louisiana', shortcode: 'LA' },
    { title: 'Maine', shortcode: 'ME' },
    { title: 'Maryland', shortcode: 'MD' },
    { title: 'Massachusetts', shortcode: 'MA' },
    { title: 'Michigan', shortcode: 'MI' },
    { title: 'Minnesota', shortcode: 'MN' },
    { title: 'Mississippi', shortcode: 'MS' },
    { title: 'Missouri', shortcode: 'MO' },
    { title: 'Montana', shortcode: 'MT' },
    { title: 'Nebraska', shortcode: 'NE' },
    { title: 'Nevada', shortcode: 'NV' },
    { title: 'New Hampshire', shortcode: 'NH' },
    { title: 'New Jersey', shortcode: 'NJ' },
    { title: 'New Mexico', shortcode: 'NM' },
    { title: 'New York', shortcode: 'NY' },
    { title: 'North Carolina', shortcode: 'NC' },
    { title: 'North Dakota', shortcode: 'ND' },
    { title: 'Ohio', shortcode: 'OH' },
    { title: 'Oklahoma', shortcode: 'OK' },
    { title: 'Oregon', shortcode: 'OR' },
    { title: 'Pennsylvania', shortcode: 'PA' },
    { title: 'Rhode Island', shortcode: 'RI' },
    { title: 'South Carolina', shortcode: 'SC' },
    { title: 'South Dakota', shortcode: 'SD' },
    { title: 'Tennessee', shortcode: 'TN' },
    { title: 'Texas', shortcode: 'TX' },
    { title: 'Utah', shortcode: 'UT' },
    { title: 'Vermont', shortcode: 'VT' },
    { title: 'Virginia', shortcode: 'VA' },
    { title: 'Washington', shortcode: 'WA' },
    { title: 'West Virginia', shortcode: 'WV' },
    { title: 'Wisconsin', shortcode: 'WI' },
    { title: 'Wyoming', shortcode: 'WY' },
    { title: 'District of Columbia', shortcode: 'DC' },
    { title: 'Puerto Rico', shortcode: 'PR' },
    { title: 'United States Minor Outlying Islands', shortcode: 'UM' },
    { title: 'Virgin Islands, U.S.', shortcode: 'VI' },
];
