import { AuctionMessageQueryDto } from '../constants/types';
import { endpoints } from '../constants/endpoints';

import { BaseApi } from './index';

export class AuctionMessagesApi extends BaseApi<any> {
  constructor() {
    super(endpoints.auctionMessages.base);
  }

  async getActiveAuctionMessages(query: Partial<AuctionMessageQueryDto>) {
    return this.fetch(endpoints.auctionMessages.active, { params: query });
  }

  async deleteAuctionMessage(auctionMessageId: string) {
    return this.delete(endpoints.auctionMessages.delete(auctionMessageId));
  }
}
