import React from 'react';
import { AuctionMessageType } from '@Encore-FastStartup-Phase-Out/encore-shared';
import { Box, Text, useColorModeValue } from '@chakra-ui/react';

import { Colors } from '../../theme/colors';

type PillProps = {
  type: AuctionMessageType;
};

const AuctionMessageTypePill = ({ type }: PillProps) => {
  const isBid = type === AuctionMessageType.Bid;
  const isGiveaway = type === AuctionMessageType.GiveawayEntry;
  const isGiveawayWinner = type === AuctionMessageType.GiveawayWinner;

  const textColorBid = useColorModeValue(Colors.light.primary, Colors.dark.primary);
  const textColorGiveaway = useColorModeValue(Colors.light.secondary, Colors.dark.secondary);
  const textColorGiveawayWinner= useColorModeValue(Colors.light.starIcon, Colors.dark.starIcon);
  const textColor = useColorModeValue(Colors.light.text, Colors.dark.text);

  return (
    <Box
      display="inline-block"
      px={'20px'}
      py={'4px'}
      bg={'transparent'}
      color="white"
      borderRadius="full"
      border={`1px solid ${isBid ? textColorBid : isGiveaway ? textColorGiveaway : isGiveawayWinner ? textColorGiveawayWinner : textColor}`}
    >
      <Text
        color={
          isBid
            ? textColorBid
            : isGiveaway
              ? textColorGiveaway
              : isGiveawayWinner
                ? textColorGiveawayWinner
                : textColor
        }
      >
        {isBid
          ? 'Bid'
          : isGiveaway
            ? 'Giveaway Entry'
            : isGiveawayWinner
              ? 'Giveaway Winner'
              : 'Text'}
      </Text>
    </Box>
  );
};

export default AuctionMessageTypePill;
