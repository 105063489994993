import { TableHeader } from './types';

export const userHeaders = [
  { displayName: 'Feature', sortKey: '', sortable: false },
  { displayName: 'Seller status', sortKey: '', sortable: false }, // for displaying user request status and seller status
  { displayName: 'Username', sortKey: 'userName', sortable: true },
  { displayName: 'Email Address', sortKey: 'email', sortable: true },
  { displayName: 'Amount Spent', sortKey: 'amountSpent', sortable: false },
  { displayName: 'Earnings', sortKey: 'earnings', sortable: false },
  { displayName: 'Signed Up Date', sortKey: 'createdAt', sortable: true },
  {
    displayName: 'Stripe Account ID',
    sortKey: 'stripeAccountId',
    sortable: false,
  },
  {
    displayName: 'Stripe Customer ID',
    sortKey: 'stripeCustomerId',
    sortable: false,
  },
  {
    displayName: 'Payment Failed Strikes',
    sortKey: 'paymentFailedStrikes',
    sortable: false,
  },
];

export const activeListingHeaders: TableHeader[] = [
  { displayName: 'Feature', sortKey: '', sortable: false },
  { displayName: 'Item name', sortKey: 'title', sortable: true },
  { displayName: 'Description', sortKey: 'description', sortable: true },
  { displayName: 'Date posted', sortKey: 'createdAt', sortable: true },
  { displayName: 'Seller', sortKey: '', sortable: false },
  { displayName: 'Type', sortKey: 'listingType', sortable: true },
  { displayName: 'Quantity', sortKey: 'inStock', sortable: true },
  { displayName: 'Price', sortKey: 'startingBid', sortable: true },
  { displayName: 'Starting bid', sortKey: 'startingBid', sortable: true },
  { displayName: 'Current bid', sortKey: 'currentBid', sortable: false },
];

export const activeAuctionMessageHeaders: TableHeader[] = [
  { displayName: 'Type', sortKey: 'type', sortable: true },
  { displayName: 'User', sortKey: 'userName', sortable: true },
  { displayName: 'Date created', sortKey: 'createdAt', sortable: true },
  { displayName: 'Listing name', sortKey: 'title', sortable: true },
  { displayName: 'Seller name', sortKey: 'userName', sortable: true },
  { displayName: 'Bid Value', sortKey: 'amount', sortable: true },
  { displayName: 'Message', sortKey: 'text', sortable: true },
  ]

export const chatHeaders: TableHeader[] = [
  { displayName: 'User', sortKey: '', sortable: false },
  { displayName: 'Other User', sortKey: '', sortable: false },
  { displayName: 'Date updated', sortKey: 'updatedAt', sortable: true },
  { displayName: 'Last message', sortKey: 'lastMessage', sortable: true },
  { displayName: 'Blocked user', sortKey: '', sortable: false },
]

export const messageHeaders: TableHeader[] = [
  { displayName: 'User', sortKey: '', sortable: false },
  { displayName: 'Other User', sortKey: '', sortable: false },
  { displayName: 'Date created', sortKey: 'createdAt', sortable: true },
  { displayName: 'Message', sortKey: 'message', sortable: true },
  { displayName: 'Seen', sortKey: 'isSeen', sortable: true },
]

export const soldListingHeaders: TableHeader[] = [
  { displayName: 'Item name', sortKey: 'listing.title', sortable: false },
  { displayName: 'Listing type', sortKey: 'listingType', sortable: false },
  { displayName: 'Item price', sortKey: 'price', sortable: true },
  { displayName: 'Shipping price', sortKey: 'shippingPrice', sortable: true },
  { displayName: 'Buyer Detail', sortKey: 'user.userName', sortable: false },
  { displayName: 'Seller Detail', sortKey: 'seller.userName', sortable: false },
  { displayName: 'Date sold', sortKey: 'createdAt', sortable: true },
  { displayName: 'Order Status', sortKey: 'status', sortable: true },
  { displayName: 'Tracking number', sortKey: 'trackingCode', sortable: false },
];

export const subcategoryHeaders: TableHeader[] = [
  { displayName: 'Title', sortKey: 'title', sortable: false },
  { displayName: 'Minimum Package Weight In Oz', sortKey: 'minPackageWeightInOz', sortable: true },
  { displayName: 'Category', sortKey: 'category.title', sortable: false },
];

export const ratingHeaders: TableHeader[] = [
  { displayName: 'Buyer', sortKey: '', sortable: false },
  { displayName: 'Seller', sortKey: '', sortable: false },
  { displayName: 'Date', sortKey: 'createdAt', sortable: true },
  { displayName: 'Rating', sortKey: 'rate', sortable: true },
  { displayName: 'Comment', sortKey: 'experience', sortable: true },
  { displayName: 'Order ID', sortKey: 'orderId', sortable: true },
];
