import { makeAutoObservable } from 'mobx';
import { ISubCategory } from '@Encore-FastStartup-Phase-Out/encore-shared';

import { SubcategoryApi } from '../api/SubcategoryApi';
import { PaginatedQueryDto } from '../constants/types';

export class SubcategoryStore {
  api: SubcategoryApi;
  loading: boolean = false;
  subcategories: ISubCategory[] = [];
  totalSubcategories: number = 0;
  displayedSubcategory: any;

  constructor() {
    this.api = new SubcategoryApi();
    makeAutoObservable(this, undefined, { autoBind: true });
  }

  setDisplayedSubcategory(subcategory: any) {
    this.displayedSubcategory = subcategory
  };

  setSubcategories(subcategories: ISubCategory[]) {
    this.subcategories = subcategories;
  }

  setTotalSubcategories(totalSubcategories: number) {
    this.totalSubcategories = totalSubcategories;
  }

  setLoading(loading: boolean) {
    this.loading = loading;
  }

  async updateSubcategoryInStore(subcategory: ISubCategory) {
    const index = this.subcategories.findIndex((s) => s.id === subcategory.id);
    this.setDisplayedSubcategory(subcategory);

    if (index !== -1) {
      this.subcategories[index] = subcategory;
    }
  }

  async getSubcategories(query: Partial<PaginatedQueryDto>) {
    this.setLoading(true);

    try {
      const response = await this.api.getSubcategories(query);
      this.setTotalSubcategories(response.count);

      if (query.page === 1) {
        this.setSubcategories(response.items);
      } else {
        this.setSubcategories([...this.subcategories, ...response.items]);
      }
    } catch (error) {
      console.error(error);
    } finally {
      this.setLoading(false);
    }
  }

  async editSubcategory(id: string, data: any) {
    await this.api.editSubcategory(id, data);
    await this.updateSubcategoryInStore({ ...this.displayedSubcategory, ...data });
  }
}
