import React, { useEffect, useState } from 'react';
import { Box, Td, Tr, Text, useDisclosure } from '@chakra-ui/react';
import { observer } from 'mobx-react-lite';

import { useStore } from '../../hooks/useStore';
import CustomTable from '../../components/table/CustomTable';
import { subcategoryHeaders } from '../../constants/tableHeaders';
import DetailDrawer from '../../components/drawer/DetailDrawer';
import { UserDto } from '../../constants/types';
import SubcategoryDetails from '../../components/drawer/SubcategoryDetails';

const SubcategoryScreen = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [currentPage, setCurrentPage] = useState(1);
  const {
    subcategoryStore: { subcategories, totalSubcategories, getSubcategories, loading, setDisplayedSubcategory },
  } = useStore();

  useEffect(() => {
    setCurrentPage(1);
    (async () => {
      await getSubcategories({ page: 1, limit: 10 });
    })();
  }, []);

  const handleRowClick = (row: any) => {
    setDisplayedSubcategory(row);
    onOpen();
  };

  return (
    <Box>
      <CustomTable
        headers={subcategoryHeaders}
        rowsPerPage={10}
        totalRows={totalSubcategories}
        fetchDataTable={getSubcategories}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        loading={loading}
      >
        {(subcategories || []).map((subcategory: any) => (
          <Tr key={subcategory.id} onClick={() => handleRowClick(subcategory)}>
            <Td>{subcategory.title}</Td>
            <Td>{subcategory.minPackageWeightInOz}</Td>
            <Td>{subcategory.category?.title}</Td>
          </Tr>
        ))}
      </CustomTable>
      <DetailDrawer isOpen={isOpen} onClose={onClose}>
        <SubcategoryDetails />
      </DetailDrawer>
    </Box>
  );
};

export default observer(SubcategoryScreen);
