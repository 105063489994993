"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TabsNames = exports.UserStatus = exports.UserRequestStatus = exports.ReportTypes = exports.EventScope = exports.Events = exports.EventType = exports.RedisKeys = exports.Role = exports.TaskTypes = exports.CommunityUserType = exports.FirebaseErrors = exports.Errors = exports.OrderIssueOptions = exports.LabelSize = exports.StripePaymentStatus = exports.ShippingService = exports.OfferStatus = exports.FirebaseCollection = exports.OrderShippingStatus = exports.OrderStatus = exports.AuctionMessageType = exports.RatingSteps = exports.IdentityStatus = exports.AddressType = exports.ListingStatus = exports.ShippingType = exports.ListingType = exports.IconDefinition = exports.NotificationsTypes = exports.Theme = exports.Language = void 0;
var Language;
(function (Language) {
    Language["English"] = "en";
})(Language || (exports.Language = Language = {}));
var Theme;
(function (Theme) {
    Theme["Light"] = "light";
    Theme["Dark"] = "dark";
})(Theme || (exports.Theme = Theme = {}));
var NotificationsTypes;
(function (NotificationsTypes) {
    NotificationsTypes["AUCTION_WON"] = "auctionWon";
    NotificationsTypes["GIVEAWAY_WON"] = "giveawayWon";
    NotificationsTypes["AUCTION_ENDED"] = "auctionEnded";
    NotificationsTypes["OFFER_ACCEPTED"] = "offerAccepted";
    NotificationsTypes["OFFER_DECLINED"] = "offerDeclined";
    NotificationsTypes["OFFER_RECEIVED"] = "offerReceived";
    NotificationsTypes["AUCTION_CREATED"] = "auctionCreated";
    NotificationsTypes["AUCTION_ENDING_SOON"] = "auctionEndingSoon";
    NotificationsTypes["ORDER_STATUS_UPDATE"] = "orderStatusUpdate";
    NotificationsTypes["YOU_WERE_OUTBID"] = "youWereOutbid";
    NotificationsTypes["ORDER_CANCELLED"] = "orderCancelled";
    NotificationsTypes["RETRY_PAYMENT_SUCCESS"] = "retryPaymentSuccess";
    NotificationsTypes["MAX_BID_OUTBID"] = "maxBidOutbid";
    NotificationsTypes["NEW_MESSAGE"] = "newMessage";
})(NotificationsTypes || (exports.NotificationsTypes = NotificationsTypes = {}));
var IconDefinition;
(function (IconDefinition) {
    IconDefinition["ChevronDown"] = "chevronDown";
    IconDefinition["ChevronUp"] = "chevronUp";
    IconDefinition["Clock"] = "clock";
    IconDefinition["Category"] = "category";
    IconDefinition["ArrowRight"] = "arrowRight";
    IconDefinition["ArrowLeft"] = "arrowLeft";
    IconDefinition["Close"] = "close";
    IconDefinition["Star"] = "star";
    IconDefinition["Trash"] = "trash";
    IconDefinition["ShippingCard"] = "shippingCar";
    IconDefinition["AddCard"] = "addCard";
    IconDefinition["LogoWithText"] = "logoWithText";
    IconDefinition["Back"] = "back";
    IconDefinition["Card"] = "card";
    IconDefinition["Edit"] = "edit";
    IconDefinition["TickCircle"] = "tickCircle";
    IconDefinition["ShippingPackageStatus"] = "shippingPackageStatus";
    IconDefinition["Bell"] = "bell";
    IconDefinition["CubeScan"] = "CubeScan";
    IconDefinition["ShippingLabel"] = "shippingLabel";
    IconDefinition["PackingSlip"] = "packingSlip";
    IconDefinition["Messages"] = "messages";
    IconDefinition["MessageQuestion"] = "messageQuestion";
    IconDefinition["Export"] = "export";
    IconDefinition["Upload"] = "upload";
    IconDefinition["DocumentUpload"] = "documentUpload";
    IconDefinition["Rotate"] = "rotate";
    IconDefinition["Cup"] = "cup";
    IconDefinition["Share"] = "share";
    IconDefinition["Settings"] = "settings";
    IconDefinition["Instagram"] = "instagram";
    IconDefinition["TikTok"] = "tiktok";
    IconDefinition["ChevronLeft"] = "chevronLeft";
    IconDefinition["CategoryClose"] = "categoryClose";
    IconDefinition["ListingInfo"] = "listingInfo";
    IconDefinition["Present"] = "present";
    IconDefinition["Caution"] = "caution";
    IconDefinition["Phone"] = "phone";
})(IconDefinition || (exports.IconDefinition = IconDefinition = {}));
var ListingType;
(function (ListingType) {
    ListingType["DEFAULT"] = "default";
    ListingType["AUCTION"] = "auction";
    ListingType["GIVEAWAY"] = "giveaway";
})(ListingType || (exports.ListingType = ListingType = {}));
var ShippingType;
(function (ShippingType) {
    ShippingType["BUYER_PAYS"] = "buyer_pays";
    ShippingType["SELLER_PAYS"] = "seller_pays";
})(ShippingType || (exports.ShippingType = ShippingType = {}));
var ListingStatus;
(function (ListingStatus) {
    ListingStatus["ACTIVE"] = "active";
    ListingStatus["DRAFT"] = "draft";
    ListingStatus["ENDED"] = "auction_ended";
    ListingStatus["SOLD"] = "sold";
})(ListingStatus || (exports.ListingStatus = ListingStatus = {}));
var AddressType;
(function (AddressType) {
    AddressType["DEFAULT"] = "DEFAULT";
    AddressType["RETURN_ADDRESS"] = "RETURN_ADDRESS";
})(AddressType || (exports.AddressType = AddressType = {}));
var IdentityStatus;
(function (IdentityStatus) {
    IdentityStatus["Pending"] = "Pending";
    IdentityStatus["Failed"] = "Failed";
    IdentityStatus["Verified"] = "Verified";
})(IdentityStatus || (exports.IdentityStatus = IdentityStatus = {}));
var RatingSteps;
(function (RatingSteps) {
    RatingSteps[RatingSteps["StarRating"] = 1] = "StarRating";
    RatingSteps[RatingSteps["ExperienceRating"] = 2] = "ExperienceRating";
    RatingSteps[RatingSteps["SkipRating"] = 3] = "SkipRating";
})(RatingSteps || (exports.RatingSteps = RatingSteps = {}));
var AuctionMessageType;
(function (AuctionMessageType) {
    AuctionMessageType["Bid"] = "bid";
    AuctionMessageType["Text"] = "text";
    AuctionMessageType["GiveawayEntry"] = "giveawayEntry";
    AuctionMessageType["GiveawayWinner"] = "giveawayWinner";
})(AuctionMessageType || (exports.AuctionMessageType = AuctionMessageType = {}));
var OrderStatus;
(function (OrderStatus) {
    OrderStatus["PENDING"] = "Pending";
    OrderStatus["CONFIRMED"] = "Confirmed";
    OrderStatus["SHIPPED"] = "Shipped";
    OrderStatus["ORDER_CANCELLED"] = "ORDER_CANCELLED";
    OrderStatus["PAYMENT_PENDING"] = "PAYMENT_PENDING";
    OrderStatus["PAYMENT_EXPIRED"] = "PAYMENT_EXPIRED";
    OrderStatus["PAYMENT_FAILED"] = "PAYMENT_FAILED";
    OrderStatus["PAYMENT_CONFIRMED"] = "PAYMENT_CONFIRMED";
    OrderStatus["SHIPPING_SHIPPED"] = "SHIPPING_SHIPPED";
    OrderStatus["SHIPPING_AWAITING"] = "SHIPPING_AWAITING";
    OrderStatus["SHIPPING_LABEL_PRINTED"] = "SHIPPING_LABEL_PRINTED";
    OrderStatus["SHIPPING_DELIVERED"] = "SHIPPING_DELIVERED";
    OrderStatus["SHIPPING_MARKED_SHIPPED"] = "SHIPPING_MARKED_SHIPPED";
    OrderStatus["INFORMATION_RECEIVED"] = "information_received";
    OrderStatus["DELAYED"] = "delayed";
    OrderStatus["DELIVERY_RESCHEDULED"] = "delivery_rescheduled";
    OrderStatus["DELIVERY_SCHEDULED"] = "delivery_scheduled";
    OrderStatus["OUT_FOR_DELIVERY"] = "out_for_delivery";
    OrderStatus["PACKAGE_ACCEPTED"] = "package_accepted";
    OrderStatus["PACKAGE_ARRIVED"] = "package_arrived";
    OrderStatus["PACKAGE_DEPARTED"] = "package_departed";
    OrderStatus["PACKAGE_FORWARD"] = "package_forward";
    OrderStatus["PACKAGE_PROCESSED"] = "package_processed";
    OrderStatus["PACKAGE_PROCESSING"] = "package_processing";
    OrderStatus["DELIVERED"] = "delivered";
    OrderStatus["RETURN_TO_SENDER"] = "return_to_sender";
    OrderStatus["PACKAGE_DISPOSED"] = "package_disposed";
    OrderStatus["OTHER"] = "other";
    OrderStatus["ADDRESS_ISSUE"] = "address_issue";
    OrderStatus["CONTACT_CARRIER"] = "contact_carrier";
    OrderStatus["DELIVERY_ATTEMPTED"] = "delivery_attempted";
    OrderStatus["LOCATION_INACCESSIBLE"] = "location_inaccessible";
    OrderStatus["NOTICE_LEFT"] = "notice_left";
    OrderStatus["PACKAGE_DAMAGED"] = "package_damaged";
    OrderStatus["PACKAGE_HELD"] = "package_held";
    OrderStatus["PICKUP_AVAILABLE"] = "pickup_available";
    OrderStatus["RESCHEDULE_DELIVERY"] = "reschedule_delivery";
    OrderStatus["PACKAGE_UNCLAIMED"] = "package_unclaimed";
    OrderStatus["PACKAGE_UNDELIVERED"] = "package_undelivered";
    OrderStatus["PACKAGE_LOST"] = "package_lost";
    OrderStatus["CANCELLED"] = "cancelled";
})(OrderStatus || (exports.OrderStatus = OrderStatus = {}));
// as it should be displayed in the UI
var OrderShippingStatus;
(function (OrderShippingStatus) {
    OrderShippingStatus["ACTION_NEEDED"] = "Action needed! Payment failed!";
    OrderShippingStatus["AWAITING_BUYER_PAYMENT"] = "Awaiting buyer payment";
    OrderShippingStatus["LABEL_PRINTED"] = "Label Printed";
    OrderShippingStatus["PREPARING"] = "Preparing";
    OrderShippingStatus["AWAITING_SHIPPING"] = "Awaiting Shipping";
    OrderShippingStatus["MARKED_SHIPPED"] = "Marked Shipped";
    OrderShippingStatus["SHIPPED"] = "Shipped";
    OrderShippingStatus["IN_TRANSIT"] = "In Transit";
    OrderShippingStatus["ISSUE"] = "Issue: Contact USPS";
    OrderShippingStatus["DELIVERED"] = "Delivered";
    OrderShippingStatus["CANCELLED"] = "Cancelled";
    OrderShippingStatus["RETURNED"] = "Returned to sender";
})(OrderShippingStatus || (exports.OrderShippingStatus = OrderShippingStatus = {}));
var FirebaseCollection;
(function (FirebaseCollection) {
    FirebaseCollection["ListingDetails"] = "listing-details";
    FirebaseCollection["AuctionMessages"] = "auction-messages";
})(FirebaseCollection || (exports.FirebaseCollection = FirebaseCollection = {}));
var OfferStatus;
(function (OfferStatus) {
    OfferStatus["PENDING"] = "PENDING";
    OfferStatus["ACCEPTED"] = "ACCEPTED";
    OfferStatus["DECLINED"] = "DECLINED";
    OfferStatus["EXPIRED"] = "EXPIRED";
})(OfferStatus || (exports.OfferStatus = OfferStatus = {}));
var ShippingService;
(function (ShippingService) {
    ShippingService["GroundAdvantage"] = "Ground Advantage";
    ShippingService["PriorityMail"] = "Priority Mail";
})(ShippingService || (exports.ShippingService = ShippingService = {}));
var StripePaymentStatus;
(function (StripePaymentStatus) {
    StripePaymentStatus["Succeeded"] = "succeeded";
    StripePaymentStatus["RequiresPaymentMethod"] = "required_payment_method";
})(StripePaymentStatus || (exports.StripePaymentStatus = StripePaymentStatus = {}));
var LabelSize;
(function (LabelSize) {
    LabelSize["SIZE_8_5_X_11"] = "8.5\u201D x 11\u201D";
    LabelSize["SIZE_4_X_6"] = "4\u201D x 6\u201D";
})(LabelSize || (exports.LabelSize = LabelSize = {}));
var OrderIssueOptions;
(function (OrderIssueOptions) {
    OrderIssueOptions["DamagedItem"] = "Damaged Item";
    OrderIssueOptions["LostOrDidntReceive"] = "Lost / Didn\u2019t receive item";
    OrderIssueOptions["DeliveredToWrongAddress"] = "Item delivered to the wrong address";
    OrderIssueOptions["ReceivedDifferentItem"] = "Received different item";
})(OrderIssueOptions || (exports.OrderIssueOptions = OrderIssueOptions = {}));
var Errors;
(function (Errors) {
    Errors["PaymentDeletedWithActiveBids"] = "paymentDeletedWithActiveBids";
})(Errors || (exports.Errors = Errors = {}));
var FirebaseErrors;
(function (FirebaseErrors) {
    FirebaseErrors["INVALID_EMAIL"] = "auth/invalid-email";
    FirebaseErrors["USER_NOT_FOUND"] = "auth/user-not-found";
    FirebaseErrors["WRONG_PASSWORD"] = "auth/wrong-password";
    FirebaseErrors["REQUIRES_RECENT_LOGIN"] = "auth/requires-recent-login";
    FirebaseErrors["EMAIL_ALREADY_IN_USE"] = "auth/email-already-in-use";
    FirebaseErrors["INVALID_CREDENTIAL"] = "auth/invalid-credential";
    FirebaseErrors["WEAK_PASSWORD"] = "auth/weak-password";
})(FirebaseErrors || (exports.FirebaseErrors = FirebaseErrors = {}));
var CommunityUserType;
(function (CommunityUserType) {
    CommunityUserType["FOLLOWER"] = "follower";
    CommunityUserType["FOLLOWING"] = "following";
})(CommunityUserType || (exports.CommunityUserType = CommunityUserType = {}));
var TaskTypes;
(function (TaskTypes) {
    TaskTypes["SHIPPED"] = "shipped";
    TaskTypes["TWO_DAYS_REMINDER"] = "two_days_reminder";
    TaskTypes["THREE_DAYS_REMINDER"] = "three_days_reminder";
    TaskTypes["AUCTION_PAYMENT_FAILED"] = "auction_payment_failed";
})(TaskTypes || (exports.TaskTypes = TaskTypes = {}));
var Role;
(function (Role) {
    Role["USER"] = "user";
    Role["SELLER"] = "seller";
    Role["BUYER"] = "buyer";
    Role["ADMIN"] = "admin";
    Role["GUEST"] = "guest";
})(Role || (exports.Role = Role = {}));
// Redis keys use to read and write listing data for bidding.
var RedisKeys;
(function (RedisKeys) {
    RedisKeys["WinningBid"] = "winningBid";
    RedisKeys["EndTime"] = "endTime";
    RedisKeys["ListingStock"] = "listingStock";
})(RedisKeys || (exports.RedisKeys = RedisKeys = {}));
var EventType;
(function (EventType) {
    EventType["User"] = "user";
    EventType["Listing"] = "listing";
})(EventType || (exports.EventType = EventType = {}));
var Events;
(function (Events) {
    Events["NewBid"] = "newBid";
    Events["ItemOutOfStock"] = "itemOutOfStock";
    Events["ListingVideoUploaded"] = "listingVideoUploaded";
    Events["ListingEndTimeIncremented"] = "listingEndTimeIncremented";
    Events["StockDecremented"] = "stockDecremented";
    Events["BidAlreadyExists"] = "bidAlreadyExists";
    Events["UserOutbid"] = "userOutbid";
    Events["AuctionEnded"] = "auctionEnded";
    Events["PaymentSucceeded"] = "paymentSuccess";
    Events["PaymentFailed"] = "paymentFailed";
    Events["ListingStockEmpty"] = "listingStockEmpty";
    Events["UserMessage"] = "userMessage";
    Events["FeedListingUpdated"] = "feedListingUpdated";
    Events["GiveawayEnded"] = "giveawayEnded";
    Events["GiveawayEntriesUpdated"] = "giveawayEntriesUpdated";
    Events["NewChat"] = "newChat";
    Events["NewMessage"] = "newMessage";
    Events["UpdateChat"] = "updateChat";
    Events["UpdateUnreadCount"] = "updateUnreadCount";
    Events["MaxBidHistory"] = "maxBidHistory";
    Events["BlockChat"] = "blockChat";
})(Events || (exports.Events = Events = {}));
var EventScope;
(function (EventScope) {
    EventScope["User"] = "user";
    EventScope["Listing"] = "listing";
})(EventScope || (exports.EventScope = EventScope = {}));
var ReportTypes;
(function (ReportTypes) {
    ReportTypes["NUDITY"] = "nudity";
    ReportTypes["HARMFUL"] = "harmful";
    ReportTypes["SCAM"] = "scam";
    ReportTypes["OTHER"] = "other";
})(ReportTypes || (exports.ReportTypes = ReportTypes = {}));
var UserRequestStatus;
(function (UserRequestStatus) {
    UserRequestStatus["PENDING"] = "PENDING";
    UserRequestStatus["APPROVED"] = "APPROVED";
    UserRequestStatus["REJECTED"] = "REJECTED";
})(UserRequestStatus || (exports.UserRequestStatus = UserRequestStatus = {}));
var UserStatus;
(function (UserStatus) {
    UserStatus["ACTIVE"] = "Active";
    UserStatus["DEACTIVE"] = "DeActive";
})(UserStatus || (exports.UserStatus = UserStatus = {}));
var TabsNames;
(function (TabsNames) {
    TabsNames["HOME"] = "Home";
    TabsNames["AUCTIONS"] = "Auctions";
    TabsNames["BUYNOW"] = "Buy Now";
    TabsNames["SELLERS"] = "Sellers";
})(TabsNames || (exports.TabsNames = TabsNames = {}));
