import { IChat, IMessage } from '@Encore-FastStartup-Phase-Out/encore-shared';
import { makeAutoObservable } from 'mobx';

import { ChatApi } from '../api/ChatApi';
import { ChatQueryDto } from '../constants/types';

export class ChatStore {
  api: ChatApi;
  chats: IChat[] = [];
  messages: IMessage[] = [];
  loading: boolean = false;
  chatSearch: string = '';
  messageSearch: string = '';
  totalChats: number = 0;
  totalMessages: number = 0;

  constructor() {
    this.api = new ChatApi();
    makeAutoObservable(this, undefined, { autoBind: true });
  }

  setChats(chats: IChat[]) {
    this.chats = chats;
  }

  setMessages(messages: IMessage[]) {
    this.messages = messages;
  }

  setLoading(loading: boolean) {
    this.loading = loading;
  }

  setChatSearch(value: string) {
    this.chatSearch = value;
  }

  setMessageSearch(value: string) {
    this.messageSearch = value;
  }

  setTotalChats(value: number) {
    this.totalChats = value;
  }

  setTotalMessages(value: number) {
    this.totalMessages = value;
  }

  async fetchChats(query?: Partial<ChatQueryDto>) {
    query = {
      ...(this.chatSearch && { search: this.chatSearch }),
      ...(query || {}),
    };

    this.setLoading(true);
    const res = await this.api.getChats(query);
    this.setTotalChats(res.count);
    if (query?.page === 1) {
      this.setChats(res.items);
    } else {
      this.setChats([...this.chats, ...res.items]);
    }
    this.setLoading(false);
  }

  async fetchMessages(query?: Partial<ChatQueryDto>) {
    query = {
      ...(this.messageSearch && { search: this.messageSearch }),
      ...(query || {}),
    };

    this.setLoading(true);
    const res = await this.api.getMessages(query);
    this.setTotalMessages(res.count);
    if (query?.page === 1) {
      this.setMessages(res.items);
    } else {
      this.setMessages([...this.messages, ...res.items]);
    }
    this.setLoading(false);
  }

  async deleteChat(chatId: string) {
    await this.api.deleteChat(chatId);
    const index = this.chats.findIndex((chat) => chat.id === chatId);
    const copyChats = [...this.chats];
    copyChats.splice(index, 1);
    this.setTotalChats(this.totalChats - 1);

    this.setChats(copyChats);
  }

  async deleteMessage(messageId: string) {
    await this.api.deleteMessage(messageId);
    const index = this.messages.findIndex((message) => message.id === messageId);
    const copyMessages = [...this.messages];
    copyMessages.splice(index, 1);
    this.setTotalMessages(this.totalMessages - 1);

    this.setMessages(copyMessages);
  }
}
