import React, { useState } from 'react';
import {
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  IconButton,
  useColorModeValue,
} from '@chakra-ui/react';
import { BiDotsVerticalRounded, BiTrash } from 'react-icons/bi';
import { observer } from 'mobx-react-lite';

import { Colors } from '../../theme/colors';
import ConfirmationModal from '../modals/ConfirmationModal';

interface DeleteMenuProps {
  onDelete: () => void;
  title: string;
  subtitle: string;
  cancelActionLabel: string;
  confirmActionLabel: string;
  deleteActionLabel: string;
}

const DeleteMenu = ({
  onDelete,
  title,
  subtitle,
  cancelActionLabel,
  confirmActionLabel,
  deleteActionLabel,
}: DeleteMenuProps) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const bgColor = useColorModeValue(
    Colors.light.background,
    Colors.dark.background,
  );

  const handleClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    setIsModalOpen(true);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <Menu>
        <MenuButton
          as={IconButton}
          aria-label="Options"
          icon={<BiDotsVerticalRounded />}
          variant="outline"
          borderColor={'transparent'}
          onClick={(e) => e.stopPropagation()}
        />
        <MenuList bg={bgColor}>
          <MenuItem bg={bgColor} icon={<BiTrash />} onClick={handleClick}>
            {deleteActionLabel}
          </MenuItem>
        </MenuList>
      </Menu>
      <ConfirmationModal
        isOpen={isModalOpen}
        onClose={handleModalClose}
        title={title}
        subtitle={subtitle}
        cancelActionLabel={cancelActionLabel}
        confirmActionLabel={confirmActionLabel}
        onCancel={handleModalClose}
        onConfirm={onDelete}
      />
    </>
  );
};

export default observer(DeleteMenu);
